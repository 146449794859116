/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import Layout from 'components/shared/Layout'
import TitleDefault from 'components/elements/TitleDefault'

const NotFoundPage = () => (
  <Layout>
    <div className="container py-5">
      <TitleDefault>404</TitleDefault>
      <p>Pagina niet gevonden.</p>
    </div>
  </Layout>
)

export default NotFoundPage
