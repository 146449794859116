/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components'

const TitleDefault = styled.h1`
  font-size: ${props => props.theme.font.size.xl};
  font-weight: ${props => props.theme.font.weight.l};
  font-family: ${props => props.theme.font.family.secondary};
`

export default TitleDefault